.header {
  width: 100%;
  height: 7rem;
  background-color: $backgroundColor;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 1rem 2rem;

  &__container {
    width: 100%;
    height: 100%;
    @include flex-center-space-between;

    .page-logo {
      height: 100%;
      @include flex-column-space-between;

      &__title {
        animation: logoEffect 2s forwards;
        font-size: 2.8rem;
        font-family: $headerFontFamily;
        line-height: 2.8rem;
      }

      &__professions {
        @include flex-center-space-between;

        &--item {
          font-size: 1.2rem;
          z-index: 10;

          &:nth-child(1) {
            animation: profesionEffect 6s forwards;
          }
          &:nth-child(2) {
            animation: profesionEffect 4.5s forwards;
          }
          &:nth-child(3) {
            animation: profesionEffect 3s forwards;
          }
        }
      }
    }
  }
}

@include respond-to($tablet) {
  .header {
    padding: 2rem;
    height: 9rem;

    &__container {
      .page-logo {
        &__title {
          font-size: 4rem;
        }

        &__professions {
          &--item {
            font-size: 2rem;
          }
        }
      }
    }
  }
}


@include respond-to($sm-desktop) {
  .header {
    height: 100vh;
    z-index: 2;
    text-align: left;
    border-right: 0.1rem solid $borderColor;
    width: 28%;
    max-width: 330px;
    padding: 7rem 2rem;

    &__container {
      width: auto;
      height: auto;
      flex-direction: column;
      align-items: center;

      .page-logo {
        border-bottom: 0.1rem solid $borderColor;
        height: auto;
        width: 100%;
        max-width: 230px;

        &__professions {
          width: 100%;
          height: auto;
          margin-top: .5rem;

          &--item {
            font-size: 1.8rem;
          }
        }
      }
    }

    &__line {
      width: 300px;
      height: 1px;
      background: $borderColor;
      position: absolute;
      bottom: 10%;
      right: -10%;
    }
  }
}

