.contact {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    @include sectionName;
  }

  &__email {
    font-size: 2rem;
    text-align: center;
  }
}


@include respond-to($tablet) {
  .contact {
    &__title {
      @include sectionNameDesktop;
    }

    &__email {
      font-size: 2.5rem;
    }
  }
}

@include respond-to($sm-desktop) {
  .contact {
    width: 50%;
    margin: 0 35%;
  }
}
