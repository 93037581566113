// mobile section name
@mixin sectionName {
  font-size: 3rem;
  text-align: center;
  padding-bottom: 2.5rem;
}

@mixin sectionNameDesktop {
  font-size: 5rem;
  padding-bottom: 3rem;
}

@mixin circuit {
  width: 100%;
  height: auto;
}

@mixin borderBottom {
  border-bottom: 0.1rem solid $borderColor;
}

@mixin flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin respond-to($media) {
  @media #{$media} {
    @content;
  }
}

