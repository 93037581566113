*{
  margin: 0;
  background: 0;
  border: 0;
  box-sizing: border-box;

  &:focus {
    outline: none!important;
    box-shadow: none!important;
  }
}

html {
  font-size: 10px;
  background-color: $backgroundColor;
}

body {
  font-family: $mainFontFamily;
  font-weight: $light;
  color: $fontColor;
  background-color: $backgroundColor;

  // firefox style
  scrollbar-color: darken($fontColor, 80%) darken($fontColor, 40%);
  scrollbar-width: thin !important;

  // chrom
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $backgroundColor;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px $backgroundColor;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: darken($fontColor, 10%);
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px $backgroundColor;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($fontColor, 20%);
  }
}

.main {
  display: block;
  padding-top: 7rem;
  animation: backgroudEffect 3s forwards;
  min-height: 100vh;
}

.main-container {
  padding: 2rem 2rem;
}

h1,
h2 {
  display: none;
}

h3,
h4 {
  font-weight: $light;
}

ul {
  -webkit-padding-start: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: $fontColor;
}

strong {
  font-weight: $light;
}

figure, img {
  margin: 0;
}

.react-images__view {
  img {
    max-height: 90vh!important;
  }
}

@include respond-to($tablet) {
  .main {
    padding-top: 9rem;
  }
}

@include respond-to($sm-desktop) {
  .main {
    padding-top: 0;
    width: 50%;
    margin: 0 35%;
  }
  .main.main-home {
    width: 100%;
    margin: 0%;
  }
}

@media screen and (min-aspect-ratio: 13/9) and (max-width: 1023px) {
  #root:not(.modal-visible), {
    > * {
      display: none!important;
    }
    position: relative;
    &:before {
      content: 'Obróć ekran';
      font-size: 3rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000000;
    }
  }
}
