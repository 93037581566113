.biography {
  font-size: 1.4rem;

  &__title {
    @include sectionName;
  }

  &__figure {
    padding-bottom: 2rem;

    &--image {
      @include circuit;
    }
  }

  &__paragraph {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3rem;
    padding-bottom: 1.2rem;
    @include borderBottom;
  }

  .biography__paragraph--jobs-list {
    font-size: 1.6rem;
    line-height: 2rem;

    &--item {
      margin-bottom: 3rem;
    }
  }

}

@include respond-to($tablet) {
  .biography {
    padding: 7rem 2rem;

    &__title {
      @include sectionNameDesktop;
    }

    &__figure {
      padding-bottom: 4rem;
    }

    &__paragraph {
      width: 80%;
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0 auto 5rem;
      padding-bottom: 2rem;
    }
  }
}

@include respond-to($sm-desktop) {
  .biography {
    &__paragraph {
      width: 100%;
      font-size: 1.6rem;
      margin: 1.5rem 0;

      &--jobs-list {
        margin-bottom: 1.5rem;
      }
    }

    &__figure {
      padding-top: 2.5rem;
    }
  }
}
