.portfolio {
  &__title {
    @include sectionName;
  }
}

@include respond-to($tablet) {
  .portfolio {
    padding: 7rem 2rem;
    &__title {
      @include sectionNameDesktop;
    }
  }
}

@include respond-to($sm-desktop) {
}
