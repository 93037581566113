.main-navigation {
  &__list {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    text-decoration: none;
    padding: 5rem 0;
    font-size: 3rem;
    background-color: $navigationBackgroundColor;
    display: block;
    transform: translate3d(0, -200%, 0);

    &--item {
      padding: 1rem 0;
      a:hover {
        color: #ada9ad;
        border-bottom: 0.1rem solid #444244;
        text-decoration: none;
      }
    }
  }

  .navigation-animation-in {
    animation: fadeInDown 2s forwards;
  }

  .navigation-animation-out {
    animation: fadeOutDown 2s forwards;
    transform: translate3d(0, 0, 0);
  }

}

.show-navigation-list {
  display: block;
}

@include respond-to($sm-desktop) {
  .main-navigation {
    width: 100%;
    max-width: 230px;

    &__hamburger {
      display: none;
    }

    &__list {
      display: block;
      position: relative;
      width: auto;
      height: auto;
      font-size: 2rem;
      background-color: transparent;
      text-align: left;
      transform: translate3d(0, 0, 0);
      animation: borderNavigationEffect 3s forwards,
      navigationEffect 1.5s forwards;

      &--item {
        padding-bottom: 2.5rem;

        &:nth-child(1) {
          animation: navigationEffect 2s forwards;
        }
        &:nth-child(2) {
          animation: navigationEffect 2.1s forwards;
        }
        &:nth-child(3) {
          animation: navigationEffect 2.2s forwards;
        }
        &:nth-child(4) {
          animation: navigationEffect 2.3s forwards;
        }
        &:nth-child(5) {
          animation: navigationEffect 2.4s forwards;
        }
      }
    }
  }
}

@include respond-to ($xl-desktop) {
  .main-navigation__list {
    font-size: 2.7rem;
  }
}
