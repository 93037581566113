.home-page {
  padding: 0;
  height: calc(100vh - 7rem);

  .landscape-container {
    height: 100%;

    .main__figure {
      height: 100%;
      width: 100vw;
      overflow: hidden;
      @include flex-center;

      &--item {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}

@include respond-to($tablet) {
  .home-page {
    height: calc(100vh - 9rem);
    .landscape-container {
      .main__figure {
        &--item {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@include respond-to($sm-desktop) {
  .home-page {
    width: 100%;
    height: 100vh;
    background-image: url(../../images/background2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.01;
    animation: backgroudEffect 7s forwards;

    .landscape-container {
      .main__figure {
        display: none;
      }
    }
  }
}
