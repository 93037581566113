.social-media-container {
  position: fixed;
  bottom: 3%;
  right: 50%;
  transform: translateX(50%);
  border-bottom: 0.1rem solid $borderColor;
  animation: socialMediaEffectMobile 3s;
  width: 100px;
  padding: .5rem;
  @include flex-center-space-between;

  .social-media-icon {
    width: 2.2rem;
    height: auto;
    margin: 0 auto;
  }
}

@include respond-to($sm-desktop) {
  .social-media-container {
    width: auto;
    height: 5rem;
    top: 6%;
    right: 2%;
    animation: socialMediaEffect 3s forwards;

    .social-media-icon {
      width: 4rem;
      height: auto;
      padding: 1rem;
    }
    .line {
      width: 1px;
      height: 20px;
      background-color: $borderColor;
      position: absolute;
      top: 10%;
      left: 6%;
      animation: borderSocialEffect 2s 2s forwards;
    }
  }
}
